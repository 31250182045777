@import './scss/sizes';

:root {
  /* 
 The variables in camelCase have the same values as those in the
 fluent-ui theme.

 Those with-dashes are meant to be localized extensions found only
 in css files.
  */


  --sidenav-width: 50px;
  --sidenav-width-expanded: 200px;

  --header-height: 50px;


  --scrollbar-width: 5px;
  --scrollbar-height: 5px;

  --themePrimary: #82C8DC;
  --themeSecondary: #EC982E;

  --themeDarkAlt: #707737;

  --neutralPrimaryAlt: #F0FCFF;
  --neutralPrimary: #3F3C3C;

  --themeTertiary: #F0FCFF;


  --active-background: #f6f6f6;
  /* Color used for the border of the `Sidenav` and `Header` components */
  --bar-border-color: #eee;

  --styles-css-text-label-font-size: large;
  --styles-css-text-label-color: lightslategray;

  --scrollbar-track-background: var(--themePrimary);
  --scrollbar-thumb-foreground: lightgray;

}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a {
  text-decoration: none;
  color: var(--themeDarkAlt);
}

.form-input-margin {
  margin-top: 2rem;
}

.with-top-inset-shadow {
  box-shadow: inset 0px 2px 2px -2px lightgrey;
}

.with-min-padding {
  padding: 1rem;
}

.no-wrap {
  white-space: nowrap;
}

.with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-wrap {
  flex-wrap: wrap;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.as-block {
  display: block;
}

.text-label {
  font-size: large;
  color: lightslategray;
}

.text-label-s2 {
  font-size: normal;
  color: rgb(131, 145, 158);
}

.MuiTabs-indicator {
  background-color: var(--themePrimary) !important;
}

.MuiTabs-root .Mui-selected {
  color: #aaa !important;
}

.MuiTab-textColorPrimary {
  color: var(--themePrimary) !important;
}

.active-indicator {
  color: rgb(202, 202, 202) !important;
}

.active-indicator.is-active {
  color: var(--themePrimary) !important;
}

.with-repeating-gradient-background {

  background-image: repeating-linear-gradient(-45deg,
      transparent 0 10px,
      var(--themePrimary_alpha_1) 10px 20px);
}

.active-indicator-bg,
.active-indicator-bg * {
  background-color: #f2f2f2 !important;
}

.active-indicator-bg.is-active,
.active-indicator-bg.is-active * {
  background-color: white !important;
}

.center-text {
  text-align: center;
}

.danger {
  color: red;
}

.disabled {
  color: #aaa;
}

.primary {
  color: var(--themePrimary);
}

.bg_primary {
  background-color: var(--themePrimary);
}

.white {
  color: white;
}

.bg_white {
  background-color: white;
}

.out_primary {
  outline: 1px solid var(--themePrimary);
}

.enforce-color * {
  color: inherit !important;
}

.enforce-font-size * {
  font-size: inherit !important;
}

.enforce-border-radius * {
  border-radius: inherit !important;
}

.enforce-border-radius-1>* {
  border-radius: inherit !important;
}

.enforce-text-transform * {
  text-transform: inherit !important;
}

.enforce-height * {
  height: inherit !important;
}

.enforce-padding * {
  padding: inherit !important;
}

.tabs-min {
  min-height: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tab-min {
  font-size: small !important;
  min-height: 0 !important;
  min-width: 0 !important;
  padding-block: 0 !important;
  padding-inline: .3rem !important;
}

.enforce-background-color * {
  background-color: inherit !important;
}

.capitalize {
  text-transform: capitalize;
}

.tabs-min {
  min-height: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tab-min {
  font-size: small !important;
  min-height: 0 !important;
  min-width: 0 !important;
  padding-block: 0 !important;
  padding-inline: .3rem !important;
}

.capitalize-important {
  text-transform: capitalize !important;
}

.scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-height);
}

.scrollbar::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--scrollbar-thumb-foreground);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track-background);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

td:has(.cell-has-danger) {
  background-color: #faa;
}

.action-buttons {
  gap: 1rem;
  padding-inline-end: 2rem;
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  justify-content: end;
  flex-direction: row;
  align-items: center;
}

.scrollbar {
  -ms-overflow-style: auto;
  scrollbar-color: var(--scrollbar-track-background) var(--scrollbar-thumb-foreground);
  scrollbar-width: var(--scrollbar-width);
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: green;
}



.fixed-page-wrapper {
  padding-top: .4rem;
  position: relative;
}

.fixed-page-wrapper.wrapper-height {

  max-height: var(--main_min-height-vh);
  height: var(--main_min-height-vh);

  max-height: var(--main_min-height-svh);
  height: var(--main_min-height-svh);
}

.fixed-page-title {
  position: absolute;
  width: 45rem;
  z-index: 3;
  top: .5rem;
  left: .5rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: var(--bodyBackground);
}

.main-page-fill {
  height: var(--main_min-height-vh);

}


table.quick-table th,
table.quick-table td {
  border-collapse: collapse;
  border-inline: 1px solid lightgray !important;
  padding-left: .4rem;
}

table.quick-table th {
  color: white;
  font-weight: 400;
  background-color: var(--themePrimary);
}

.iblock {
  display: inline-block;
}

.ifx {
  display: inline-flex;
}

.fxrw {
  flex-direction: row;
}

.fxcol{
  flex-direction: column;
}

.fx {
  display: flex;
}

.fxwrp {
  flex-wrap: wrap;
}



.ycenter {
  align-items: center;
}

.xbtwn {
  justify-content: space-between;
}

.xvnly {
  justify-content: space-evenly;
}

.g1 {
  gap: 1rem;
}

.g2 {
  gap: 2rem;
}

.g3 {
  gap: 3rem;
}



.pbs4 {
  padding-block-start: 4rem;
}


.mbs3 {
  margin-block-start: 3rem;
}




.secondary {
  color: #EC982E;
}

.fxrrv{
  flex-direction: row-reverse;
}

.fxcrv{
  flex-direction: column-reverse;
}

.bar_rail {
  display: flex;
  align-items: center;
  background-color: lightgray;
  height: .2rem;
  border-radius: .1rem;
  overflow: hidden;
}

.bar_rail>* {
  transition: width .7s;
  width: 0;
  background-color: var(--themePrimary);
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.attachment--manager {
  overflow: hidden;

  .attachment--manager-details-pane {
    transition: left .3s;
    left: 0;
    position: relative;
  }

  .attachment--manager-picker {
    display: none;
    transition: filter .4s;
    filter: blur(0px);

    &.loading {
      filter: blur(3px);
    }
  }

  &.picker-shown {
    .attachment--manager-details-pane {
      left: -10rem;
    }

    .attachment--manager-picker {
      display: flex;
    }
  }
}

.hierachy-list {

  @mixin list-border {
    content: '-';
    position: absolute;
    top: -.3rem;
    left: 1.5px;
    display: block;
    height: 100%;
    width: 2px;
    font-size: 1.2rem;
    padding-top: 0;
    text-indent: 3px;
  }


  ul {
    list-style-position: inside;
    margin: 0;
    padding: 0;
    list-style-type: square;
    position: relative;
    overflow-y: hidden;


    &:not(:first-child) {
      margin-left: .8rem;
    }

    li {

      // position: relative;
      color: #888;

      &::before {
        @include list-border();
        background-color: #888;
      }
    }

    >li:first-child::before {
      @include list-border();
      background-color: transparent;
    }
  }

  &.no-indicators ul li::before {
    background-color: transparent;
  }
}